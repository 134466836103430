import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { formatNumber } from 'utils/FormatNumber';
import styles from './styles.module.css';

export default function ProductLimitMessage({
  consumedMaxQuantity = {},
  page,
  row = false,
  skuStatus = '',
  isSkuAvailable = false,
  isSkuActive = false,
  isGift = false,
  className = '',
  isNewCard = false,
  requiredCertificates = '',
}) {
  function render() {
    if (isGift) {
      return null;
    }
    if (!isSkuActive) {
      if (page === 'cart') {
        return (
          <Box className={styles.boxPerDay}>
            <Typography className={clsx(styles.product_category)} color="error" variant="body2" component="p">
              Sản phẩm đã ngưng bán
            </Typography>
          </Box>
        );
      }
    }
    if (!isSkuAvailable) {
      if (page === 'cart') {
        return (
          <Box className={styles.boxPerDay}>
            <Typography className={clsx(styles.product_category)} color="error" variant="body2" component="p">
              Sản phẩm chưa hỗ trợ giao vùng này
            </Typography>
          </Box>
        );
      }
    }
    if (skuStatus === 'OUT_OF_STOCK') {
      if (page === 'cart') {
        return (
          <Box className={styles.boxPerDay}>
            <Typography className={clsx(styles.product_category)} color="error" variant="body2" component="p">
              Sản phẩm tạm hết hàng
            </Typography>
          </Box>
        );
      }
    }
    if (skuStatus === 'SUSPENDED') {
      if (page === 'cart') {
        return (
          <Box className={styles.boxPerDay}>
            <Typography className={clsx(styles.product_category)} color="error" variant="body2" component="p">
              Sản phẩm đã ngưng bán
            </Typography>
          </Box>
        );
      }
    }
    if (skuStatus === 'STOP_PRODUCING') {
      if (page === 'cart') {
        return (
          <Box className={styles.boxPerDay}>
            <Typography className={clsx(styles.product_category)} color="error" variant="body2" component="p">
              Sản phẩm ngưng sản xuất
            </Typography>
          </Box>
        );
      }
    }
    if (requiredCertificates === 'REQUIRED_CERTIFICATE') {
      if (page === 'cart') {
        return (
          <Box className={styles.boxPerDay}>
            <Typography className={clsx(styles.product_category)} color="error" variant="body2" component="p">
              Bạn chưa đủ giấy phép để mua sản phẩm này
            </Typography>
          </Box>
        );
      }
    }
    if (consumedMaxQuantity?.maxQuantityConsumed > 0 && consumedMaxQuantity?.maxQuantityConsumed < 10000) {
      if (page === 'products' && isNewCard) {
        return <Typography className={styles?.textNewCard}>Đặt tối đa {formatNumber(consumedMaxQuantity?.maxQuantityConsumed)} sản phẩm</Typography>;
      }
      if (page === 'products') {
        return (
          <Typography className={row ? styles.text_desc : clsx(styles.text_danger_column, styles.text_desc)}>
            Đặt tối đa {formatNumber(consumedMaxQuantity?.maxQuantityConsumed)} sản phẩm
          </Typography>
        );
      }
      if (page === 'product') {
        return <Typography className={styles.text_limit}>Đặt tối đa {formatNumber(consumedMaxQuantity?.maxQuantityConsumed)} sản phẩm</Typography>;
      }
      if (page === 'cart') {
        return (
          <Box className={styles.boxPerDay}>
            <Typography className={clsx(styles.product_category)} color="error" variant="body2" component="p">
              Đặt tối đa {formatNumber(consumedMaxQuantity?.maxQuantityConsumed)} sản phẩm
            </Typography>
          </Box>
        );
      }
      if (page === 'quick-order') {
        return (
          <Typography
            color="textSecondary"
            className={row ? styles.text_danger : clsx(styles.text_danger_column_quickOrder, styles.text_desc_quick_order)}
          >
            Đặt tối đa {formatNumber(consumedMaxQuantity?.maxQuantityConsumed)} sản phẩm
          </Typography>
        );
      }
    }
    if (consumedMaxQuantity?.maxQuantityConsumed === 0 && consumedMaxQuantity?.maxQuantityType === 'PER_DAY') {
      if (page === 'products' && isNewCard) {
        return <Typography className={row ? styles.text_desc : clsx(className && className)}>Đã mua hết số lượng hôm nay</Typography>;
      }
      if (page === 'products') {
        return (
          <Typography className={row ? styles.text_desc : clsx(styles.text_danger_column, styles.text_desc)}>Đã mua hết số lượng hôm nay</Typography>
        );
      }
      if (page === 'product') {
        return (
          <Typography className={styles.text_danger}>
            Bạn đã mua hết {formatNumber(consumedMaxQuantity?.maxQuantityPerDay)} sản phẩm hôm nay. Vui lòng quay lại vào ngày mai.
          </Typography>
        );
      }
      if (page === 'cart') {
        return (
          <Box className={styles.boxPerDay}>
            <Typography className={clsx(styles.product_category)} color="error" variant="body2" component="p">
              Bạn đã mua hết {formatNumber(consumedMaxQuantity?.maxQuantityPerDay)} sản phẩm hôm nay. Vui lòng quay lại vào ngày mai.
            </Typography>
          </Box>
        );
      }
      if (page === 'quick-order') {
        return (
          <Typography
            color="textSecondary"
            className={row ? styles.text_danger : clsx(styles.text_danger_column_quickOrder, styles.text_desc_quick_order)}
          >
            Bạn đã mua hết {formatNumber(consumedMaxQuantity?.maxQuantityPerDay)} sản phẩm hôm nay. Vui lòng quay lại vào ngày mai.
          </Typography>
        );
      }
    }
    return null;
  }
  return render();
}
