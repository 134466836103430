import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { SVGPrice } from 'constants/SVG';
import { memo } from 'react';
import { formatCurrency } from 'utils/FormatNumber';
import ContractPrice from './ContractPrice';

function GetPriceCardBuy({
    isDeal,
    deal,
    isCampaign,
    isMobile,
    isMobileV2,
    isContractPrice,
    discountPercent,
    errorCode,
    currentPrice, // if isCampaign, that will be price with deal == deal.price
    styles,
    row,
    salePrice,
    displayPrice, // if isDeal, that will be price with Deal = del.price
    TrendingDownIcon,
    TrendingUpIcon,
    ERROR_CODE_CART,
    availableProducts,
    isApplyPriceFluctuation,
    cart,
    warningCode,
    iconPriceFluctuation,
    product,
    discountDetail
}) {
    // TODO: need handle special case only in cart page
    // isDeal, iconPriceFluc is next to  available price
    // isDeal & haveApplyVoucher, iconPriceFluc is next to line-through price
    // not Deal, have discountDetail, line-through displayPrice, show price with voucher
    // isDeal, have discountDetail, remove % discount, line-through deal Price, show price with voucher
    if (cart) {
        // TODO: Product is flashSale or have campaign
        if (isCampaign && availableProducts > 0) {
            return (
                <div
                    className={
                        row
                        ? clsx(styles.price_wrapper)
                        : clsx(
                            styles.price_wrapper,
                            isMobileV2 ? styles.price_wrapper_mv2 : styles.price_wrapper_column,
                            discountDetail?.totalDiscount ? styles.priceSectionVoucher_wrapper : null,
                            )
                    }
                >
                    {isContractPrice && <ContractPrice styles={styles} />}
                    <Typography className={styles.flexCenter}>
                        {discountDetail?.totalDiscount ? (
                            <div className={styles.d_flex} style={{ gap: 4 }}>
                                {isApplyPriceFluctuation && warningCode && iconPriceFluctuation(warningCode) !== '' ? (
                                    <div className={styles.fluctuation_icon_wrapper}>{iconPriceFluctuation(warningCode)}</div>
                                ) : null}
                                <span className={clsx(styles.old_price, styles.strikethrough, isMobileV2 && styles.old_price_mv2)} data-test="old-price-text">
                                    <SVGPrice displayPriceFormated={displayPrice} width="8.5" height="10" fillPath="#797979" line />
                                </span>
                            </div>
                        ) : (
                            <div className={styles.flexCenter}>
                                <span className={clsx(styles.discount_percent, isMobileV2 && styles.discount_percent_mv2)} style={{ maxHeight: 18 }}>
                                    {`-${discountPercent}%`}
                                </span>
                                <span className={clsx(styles.old_price, styles.strikethrough, isMobileV2 && styles.old_price_mv2)} data-test="old-price-text">
                                    <SVGPrice displayPriceFormated={salePrice} width="8.5" height="10" fillPath="#919aa3" line />
                                </span>
                            </div>
                        )}
                    </Typography>
                    <Typography
                        className={clsx(
                        styles.deal_price,
                        isMobileV2 && styles.deal_price_mv2,
                        isApplyPriceFluctuation && isMobile && styles.mobileGapPriceFluctuation,
                        isApplyPriceFluctuation && !isMobile && styles.gapPriceFluctuation,
                        )}
                    >
                        {discountDetail?.totalDiscount > 0 ? (
                        <>
                            <ProductPriceDecrease
                                productPrice={displayPrice}
                                quantity={product?.quantity}
                                totalDiscountPerItem={discountDetail?.totalDiscount}
                                displayPrice={displayPrice}
                                isDeal
                                iconPriceFluctuation={iconPriceFluctuation}
                                styles={styles}
                            />
                        </>
                        ) : (
                            <SVGPrice displayPriceFormated={displayPrice} width="10" height="11" fillPath="#000" notAutoScale />
                        )}
                    </Typography>
                </div>
            );
        }
        // TODO: Product is Deal
        if (isDeal && deal) {
            return (
                <div
                    className={
                        row
                        ? clsx(styles.price_wrapper, discountDetail?.totalDiscount ? styles.priceSectionVoucher_wrapper : null)
                        : clsx(
                            styles.price_wrapper,
                            isMobileV2 ? styles.price_wrapper_mv2 : styles.price_wrapper_column,
                            discountDetail?.totalDiscount ? styles.priceSectionVoucher_wrapper : null,
                            )
                    }
                >
                    <>
                        {isContractPrice && <ContractPrice styles={styles} />}
                        <div className={styles.flex_align_center}>
                        {discountDetail?.totalDiscount ? (
                            <div className={styles.d_flex} style={{gap: 4}}>
                                {isApplyPriceFluctuation && warningCode && iconPriceFluctuation(warningCode) !== '' ? (
                                    <div className={styles.fluctuation_icon_wrapper}>{iconPriceFluctuation(warningCode)}</div>
                                ) : null}
                                <span className={clsx(styles.old_price, styles.strikethrough, isMobileV2 && styles.old_price_mv2)} data-test="old-price-text">
                                    <SVGPrice displayPriceFormated={displayPrice} width="8.5" height="10" fillPath="#797979" line />
                                </span>
                            </div>
                        ) : (
                            <div className={styles.flexCenter}>
                                <span
                                    className={clsx(styles.discount_percent, isMobileV2 && styles.discount_percent_mv2)}
                                    style={{ maxHeight: 18 }}
                                >
                                    {`-${discountPercent}%`}
                                </span>
                                <span className={clsx(styles.old_price, styles.strikethrough, isMobileV2 && styles.old_price_mv2)} data-test="old-price-text">
                                    <SVGPrice displayPriceFormated={salePrice} width="8.5" height="10" fillPath="#919aa3" line />
                                </span>
                            </div>
                        )}
                        </div>
                        <div className={styles.price_icon}>
                        <Typography
                            className={clsx(
                            styles.deal_price,
                            isMobileV2 && styles.deal_price_mv2,
                            isApplyPriceFluctuation && isMobile && styles.mobileGapPriceFluctuation,
                            isApplyPriceFluctuation && !isMobile && styles.gapPriceFluctuation,
                            )}
                        >
                            {discountDetail?.totalDiscount > 0 ? (
                                <ProductPriceDecrease
                                    productPrice={displayPrice}
                                    quantity={product?.quantity}
                                    totalDiscountPerItem={discountDetail?.totalDiscount}
                                    displayPrice={displayPrice}
                                    isDeal
                                    iconPriceFluctuation={iconPriceFluctuation}
                                    styles={styles}
                                />
                            ) : (
                                <SVGPrice displayPriceFormated={displayPrice} width="10" height="11" fillPath="#000" />
                            )}
                        </Typography>
                        {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice > displayPrice && <TrendingDownIcon className={styles.down} />}
                        {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice < displayPrice && <TrendingUpIcon className={styles.up} />}
                        </div>
                    </>
                </div>
            );
        }

        return (
            <div
                className={
                row
                    ? clsx(styles.price_wrapper, discountDetail?.totalDiscount ? styles.priceSectionVoucher_wrapper : null)
                    : clsx(
                        styles.price_wrapper,
                        isMobileV2 ? styles.price_wrapper_mv2 : styles.price_wrapper_column,
                        discountDetail?.totalDiscount ? styles.priceSectionVoucher_wrapper : null,
                    )
                }
                // ref={discountDetail?.totalDiscount ? promoApplySectionRef : null}
            >
                {isContractPrice && <ContractPrice styles={styles} />}
                <div className={styles.price_icon}>
                <Typography
                    className={clsx(
                    styles.deal_price,
                    isMobileV2 && styles.deal_price_mv2,
                    isApplyPriceFluctuation && isMobile && styles.mobileGapPriceFluctuation,
                    isApplyPriceFluctuation && !isMobile && styles.gapPriceFluctuation,
                    )}
                >
                    {discountDetail?.totalDiscount > 0 ? (
                    <ProductPriceDecrease
                        productPrice={displayPrice}
                        quantity={product?.quantity}
                        totalDiscountPerItem={discountDetail?.totalDiscount}
                        displayPrice={displayPrice}
                        iconPriceFluctuation={isApplyPriceFluctuation && iconPriceFluctuation(warningCode)}
                        styles={styles}
                    />
                    ) : (
                    <SVGPrice displayPriceFormated={displayPrice} width="10" height="11" fillPath="#000" />
                    )}
                </Typography>
                {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice > displayPrice && <TrendingDownIcon className={styles.down} />}
                {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice < displayPrice && <TrendingUpIcon className={styles.up} />}
                </div>
            </div>
        );
    }
    if (isCampaign && availableProducts > 0) {
        return (
            <div
                className={
                    row ? clsx(styles.price_wrapper) : clsx(styles.price_wrapper, isMobileV2 ? styles.price_wrapper_mv2 : styles.price_wrapper_column)
                }
            >
                {isMobileV2 ? (
                    <Box className={styles.mobileDisplayPrice_container}>
                        {isContractPrice && <ContractPrice styles={styles} />}
                        <Typography className={clsx(styles.deal_price, isMobileV2 && styles.deal_price_mv2)}>
                            <SVGPrice displayPriceFormated={(displayPrice)} width="10" height="11" fillPath="#00b46e" notAutoScale />
                        </Typography>
                        <Typography className={styles.flex_align_center}>
                            <span
                                className={clsx(styles.discount_percent, isMobileV2 && styles.discount_percent_mv2, discountPercent === 0 && styles.hiddenPercent)}
                            >
                                {`- ${discountPercent}%`}
                            </span>
                            <span className={clsx(styles.old_price, styles.strikethrough, isMobileV2 && styles.old_price_mv2)} data-test="old-price-text">
                                <SVGPrice displayPriceFormated={(salePrice)} width="8.5" height="10" fillPath="#919aa3" />
                            </span>
                        </Typography>
                    </Box>
                ) : (
                    <>
                        {isContractPrice && <ContractPrice styles={styles} />}
                        <Typography className={clsx(styles.deal_price, isMobileV2 && styles.deal_price_mv2, isApplyPriceFluctuation && isMobile && styles.mobileGapPriceFluctuation, isApplyPriceFluctuation && !isMobile && styles.gapPriceFluctuation)}>
                            {isApplyPriceFluctuation && iconPriceFluctuation(warningCode)}
                            <SVGPrice displayPriceFormated={(displayPrice)} width="10" height="11" fillPath="#00b46e" notAutoScale />
                        </Typography>
                        <Typography className={styles.flex_align_center}>
                            <span
                                className={clsx(styles.discount_percent, isMobileV2 && styles.discount_percent_mv2, discountPercent === 0 && styles.hiddenPercent)}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                {`- ${discountPercent}%`}
                            </span>
                            <span className={clsx(styles.old_price, styles.strikethrough, isMobileV2 && styles.old_price_mv2)} data-test="old-price-text">
                                <SVGPrice displayPriceFormated={(salePrice)} width="8.5" height="10" fillPath="#919aa3" />
                            </span>
                        </Typography>
                    </>
                )}

                {/* <Typography className={styles.old_price}>{(salePrice)}</Typography> */}
            </div>
        );
    }
    if (!cart && isDeal && deal) {
        return (
            <div
                className={
                    row ? clsx(styles.price_wrapper) : clsx(styles.price_wrapper, isMobileV2 ? styles.price_wrapper_mv2 : styles.price_wrapper_column)
                }
            >
                {isMobileV2 ? (
                    <>
                        <Box className={styles.mobileDisplayPrice_container}>
                            {isContractPrice && <ContractPrice styles={styles} />}
                            <Typography className={clsx(styles.deal_price, isMobileV2 && styles.deal_price_mv2)}>{(deal?.price)}</Typography>
                            {discountPercent !== 0 && (
                                <Typography className={styles.flex_align_center}>
                                    <span
                                        className={clsx(
                                            styles.discount_percent,
                                            isMobileV2 && styles.discount_percent_mv2,
                                            discountPercent === 0 && styles.hiddenPercent,
                                        )}
                                    >
                                        {`- ${discountPercent}%`}
                                    </span>
                                    <span className={clsx(styles.old_price, styles.strikethrough, isMobileV2 && styles.old_price_mv2)} data-test="old-price-text">
                                        <SVGPrice displayPriceFormated={(product?.salePrice)} width="8.5" height="10" fillPath="#919aa3" />
                                    </span>
                                </Typography>
                            )}
                        </Box>
                    </>
                ) : (
                    <>
                        {/* Lấy giá từ ProductServiceV2 */}
                        {isContractPrice && <ContractPrice styles={styles} />}
                        <Typography className={clsx(styles.deal_price, isMobileV2 && styles.deal_price_mv2, isApplyPriceFluctuation && isMobile && styles.mobileGapPriceFluctuation, isApplyPriceFluctuation && !isMobile && styles.gapPriceFluctuation)}>
                            {isApplyPriceFluctuation && iconPriceFluctuation(warningCode)}{' '}
                            <SVGPrice displayPriceFormated={(deal?.price)} width="10" height="11" fillPath="#00b46e" />
                        </Typography>
                        <Typography className={styles.flex_align_center}>
                            <span
                                className={clsx(styles.discount_percent, isMobileV2 && styles.discount_percent_mv2, discountPercent === 0 && styles.hiddenPercent)}
                            >
                                {`- ${discountPercent}%`}
                            </span>
                            <span className={clsx(styles.old_price, styles.strikethrough, styles.flex_align_end, isMobileV2 && styles.old_price_mv2)} data-test="old-price-text">
                                <SVGPrice displayPriceFormated={(product?.salePrice)} width="8.5" height="10" fillPath="#919aa3" />
                            </span>
                        </Typography>
                    </>
                )}
            </div>
        );
    }

    return (
        <div
            className={row ? clsx(styles.price_wrapper) : clsx(styles.price_wrapper, isMobileV2 ? styles.price_wrapper_mv2 : styles.price_wrapper_column)}
        >
            {isMobileV2 ? (
                <>
                    <Box className={styles.mobileDisplayPrice_container}>
                        {isContractPrice && <ContractPrice styles={styles} />}
                        <div className={styles.price_icon}>
                            <Typography className={clsx(styles.deal_price, isMobileV2 && styles.deal_price_mv2, isApplyPriceFluctuation && isMobile && styles.mobileGapPriceFluctuation, isApplyPriceFluctuation && !isMobile && styles.gapPriceFluctuation)}>{isApplyPriceFluctuation && iconPriceFluctuation(warningCode)}
                                {(displayPrice)}
                            </Typography>
                            {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice > displayPrice && <TrendingDownIcon className={styles.down} />}
                            {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice < displayPrice && <TrendingUpIcon className={styles.up} />}
                        </div>
                        {isDeal && deal && (
                            <Typography className={clsx(styles.old_price, styles.strikethrough, isMobileV2 && styles.old_price_mv2)} data-test="old-price-text">
                                <SVGPrice displayPriceFormated={(salePrice)} width="8.5" height="10" fillPath="#919aa3" />
                            </Typography>
                        )}
                    </Box>
                </>
            ) : (
                <>
                    {isContractPrice && <ContractPrice styles={styles} />}
                    <div className={styles.price_icon}>
                        <Typography className={clsx(styles.deal_price, isMobileV2 && styles.deal_price_mv2, isApplyPriceFluctuation && isMobile && styles.mobileGapPriceFluctuation, isApplyPriceFluctuation && !isMobile && styles.gapPriceFluctuation, styles.flex_align_end)}>
                            {isApplyPriceFluctuation && iconPriceFluctuation(warningCode)}
                            <SVGPrice displayPriceFormated={(displayPrice)} width="10" height="11" fillPath="#00b46e" />
                        </Typography>
                        {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice > displayPrice && <TrendingDownIcon className={styles.down} />}
                        {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice < displayPrice && <TrendingUpIcon className={styles.up} />}
                    </div>
                    {/* {errorCode === ERROR_CODE_CART.CHANGED_PRICE && <Typography className={styles.old_price}>{(currentPrice)}</Typography>} */}
                    {isDeal && deal && (
                        <div className={styles.flex_align_center}>
                            {discountPercent > 0 && (
                                <span className={clsx(styles.discount_percent, isMobileV2 && styles.discount_percent_mv2)}>{`-${discountPercent}%`}</span>
                            )}
                            <span className={clsx(styles.old_price, styles.strikethrough, isMobileV2 && styles.old_price_mv2)} data-test="old-price-text">
                                <SVGPrice displayPriceFormated={(salePrice)} width="8.5" height="10" fillPath="#919aa3" />
                            </span>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

function GetPriceCardInput({
    isDeal,
    deal,
    isCampaign,
    isMobile,
    isMobileV2,
    isContractPrice,
    discountPercent,
    errorCode,
    currentPrice,
    styles,
    row,
    salePrice,
    displayPrice,
    TrendingDownIcon,
    TrendingUpIcon,
    ERROR_CODE_CART,
    wishlist,
    localPercentDealSold,
    localPercentCampaignSold,
    salePriceFormated,
    displayPriceFormated,
    isSellerList,
    isHappeningCampaign,
    productType,
    isCampaignPage,
    hidenPrice,
    beta
}) {
        // wishlist page v2
        if (wishlist) {
            // khuyến mãi
            if ((isDeal && deal && localPercentDealSold < 100) || (isCampaign && localPercentCampaignSold < 100)) {
                return (
                    <>
                        <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            className={clsx(styles.display_wishlist_price_wrapper, isMobile && styles.wrap, {
                                [styles.displayPrice_wrapper_mv2]: isMobileV2,
                            })}
                        >
                            <div style={{ display: 'flex', paddingBottom: 4 }}>
                                {isContractPrice && <ContractPrice />}
                                <span className={clsx(isMobile ? styles.discount_percent_v2 : styles.discount_percent, discountPercent === 0 && styles.hidden)}>
                                    {`-${discountPercent}%`}
                                </span>
                                <span className={clsx(styles.sale_price, styles.strikethrough, styles.flex_align_center, isMobile && styles.fs_12, salePriceFormated === displayPriceFormated && styles.hidden)}>
                                    <SVGPrice displayPriceFormated={salePriceFormated} width="8" height="9.5" fillPath="#797979" line />
                                </span>
                            </div>
                            <Grid item style={{ padding: '0', width: isMobile && !beta ? '100%' : 'auto' }}>
                                <Typography className={clsx(styles.display_price, isMobile && styles.fs_12)}>
                                    <SVGPrice displayPriceFormated={displayPriceFormated} width="10" height="11" fillPath="#292929" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                );
            }

            return (
                <div className={row ? styles.price_wrapper : clsx(styles.price_wrapper, styles.price_wrapper_column)}>
                    <Grid
                        container
                        alignItems="stretch"
                        className={clsx(
                            styles.wishlist_price_wrapper,
                            isMobile && styles.wrap,
                            {
                                [styles.displayPrice_wrapper_mv2]: isMobileV2,
                            },
                            !isMobile && styles.pt_55,
                        )}
                    >
                        <Grid item style={{ paddingLeft: '0', width: isMobile && !beta ? '100%' : 'auto' }}>
                            {isContractPrice && <ContractPrice />}
                            <Typography
                                className={clsx(styles.display_price, {
                                    [styles.display_price_mv2]: isMobileV2,
                                })}
                            >
                                <SVGPrice displayPriceFormated={displayPriceFormated} width="10" height="11" fillPath="#292929" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice > displayPrice && <TrendingDownIcon className={styles.down} />}
                            {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice < displayPrice && <TrendingUpIcon className={styles.up} />}
                        </Grid>
                    </Grid>
                    {isDeal && deal && (
                        <Grid item style={{ width: isMobile && !beta ? 'auto' : 'auto', paddingTop: isMobile ? 4 : 0 }}>
                            <Typography
                                className={clsx(styles.sale_price, styles.strikethrough, styles.flex_align_center, {
                                    [styles.sale_price_mv2]: isMobileV2,
                                })}
                            >
                                <SVGPrice displayPriceFormated={salePriceFormated} width="8" height="9.5" fillPath="#797979" line />
                            </Typography>
                        </Grid>
                    )}
                </div>
            );
        }

        if (isSellerList) {
            return (
                <>
                    <div className={styles.salePrice_wrapper}>
                        {isHappeningCampaign && salePrice && localPercentCampaignSold < 100 && (
                            <Typography
                                className={clsx(styles.sale_price, styles.strikethrough, styles.flex_align_center, {
                                    [styles.sale_price_mv2]: isMobileV2,
                                })}
                            >
                                <SVGPrice displayPriceFormated={salePriceFormated} width="8" height="9.5" fillPath="#797979" line />
                            </Typography>
                        )}
                        {!isHappeningCampaign && displayPrice && (
                            <Typography
                                className={clsx(styles.sale_price, {
                                    [styles.sale_price_mv2]: isMobileV2,
                                })}
                            >
                                <SVGPrice displayPriceFormated={displayPriceFormated} width="10" height="11" fillPath="#868585" />
                            </Typography>
                        )}
                    </div>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className={clsx(styles.displayPrice_wrapper, {
                            [styles.displayPrice_wrapper_mv2]: isMobileV2,
                        })}
                    >
                        <Grid item style={{ padding: '0' }}>
                            <Typography
                                className={clsx(styles.display_price, {
                                    [styles.display_price_mv2]: isMobileV2,
                                })}
                            >
                                {((((productType === 'FLASH_SALE' && isHappeningCampaign) || productType === 'MEGA_DAY') && localPercentCampaignSold < 100) ||
                                    (!isHappeningCampaign && !isCampaignPage)) &&
                                    displayPriceFormated}
                                {((productType === 'FLASH_SALE' && isHappeningCampaign) || productType === 'MEGA_DAY') &&
                                    localPercentCampaignSold === 100 &&
                                    salePriceFormated}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            );
        }
        if (isCampaign && localPercentCampaignSold < 100) {
            return (
                <>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className={clsx(styles.displayPrice_wrapper, isMobile && styles.wrap, {
                            [styles.displayPrice_wrapper_mv2]: isMobileV2,
                        })}
                    >
                        <Grid item style={{ padding: '0', width: isMobile && !beta ? '100%' : 'auto' }}>
                            <Typography
                                className={clsx(styles.display_price, {
                                    [styles.display_price_mv2]: isMobileV2,
                                })}
                            >
                                {((((productType === 'FLASH_SALE' && isHappeningCampaign) || productType === 'MEGA_DAY') && localPercentCampaignSold < 100) ||
                                    (!isHappeningCampaign && !isCampaignPage)) &&
                                    <SVGPrice displayPriceFormated={displayPriceFormated} width="10" height="11" fillPath="#292929" />}
                                {((productType === 'FLASH_SALE' && isHappeningCampaign) || productType === 'MEGA_DAY') &&
                                    localPercentCampaignSold === 100 &&
                                    <SVGPrice displayPriceFormated={salePriceFormated} width="10" height="11" fillPath="#292929" />}
                                {!isHappeningCampaign && isCampaignPage && `${hidenPrice}`}
                            </Typography>
                        </Grid>
                        {!isHappeningCampaign && isCampaignPage && displayPrice && (
                            <Grid item style={{ padding: '0', paddingLeft: '10px', width: isMobile && !beta ? 'auto' : 'auto', paddingTop: isMobile ? 4 : 0 }}>
                                <Typography
                                    className={clsx(styles.sale_price, styles.strikethrough, styles.flex_align_center, {
                                        [styles.sale_price_mv2]: isMobileV2,
                                    })}
                                >
                                    <SVGPrice displayPriceFormated={displayPriceFormated} width="10" height="11" fillPath="#797979" line />
                                </Typography>
                            </Grid>
                        )}
                        {isHappeningCampaign && salePrice && localPercentCampaignSold < 100 && (
                            <Grid item style={{ padding: '0', paddingLeft: '10px', width: isMobile && !beta ? 'auto' : 'auto', paddingTop: isMobile ? 4 : 0 }}>
                                <Typography
                                    className={clsx(styles.sale_price, styles.strikethrough, styles.flex_align_center, {
                                        [styles.sale_price_mv2]: isMobileV2,
                                    })}
                                >
                                    <SVGPrice displayPriceFormated={salePriceFormated} width="8" height="9.5" fillPath="#797979" line />
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </>
            );
        } else if (isDeal && deal) {
            return (
                <>
                    <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        className={clsx(styles.displayPrice_wrapper, isMobile && styles.wrap, {
                            [styles.displayPrice_wrapper_mv2]: isMobileV2,
                        })}
                        style={{ rowGap: 4 }}
                    >
                        <Grid item style={{ padding: '0', width: isMobile && !beta ? '100%' : 'auto' }}>
                            <Typography
                                className={clsx(styles.display_price, {
                                    [styles.display_price_mv2]: isMobileV2,
                                })}
                            >
                                <SVGPrice displayPriceFormated={displayPriceFormated} width="10" height="11" fillPath="#292929" />
                            </Typography>
                        </Grid>
                        <Grid item style={{ padding: '0', paddingLeft: '10px', width: isMobile && !beta ? 'auto' : 'auto', paddingTop: isMobile ? 4 : 0 }}>
                            <Typography
                                className={clsx(styles.sale_price, styles.strikethrough, styles.flex_align_center, {
                                    [styles.sale_price_mv2]: isMobileV2,
                                })}
                            >
                                <SVGPrice displayPriceFormated={salePriceFormated} width="8" height="9.5" fillPath="#797979" line />
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            );
        }

        return (
            <div className={row ? styles.price_wrapper : clsx(styles.price_wrapper, styles.price_wrapper_column)}>
                <Grid
                    container
                    alignItems="stretch"
                    className={clsx(
                        styles.displayPrice_wrapper,
                        isMobile && styles.wrap,
                        {
                            [styles.displayPrice_wrapper_mv2]: isMobileV2,
                        },
                        wishlist && styles.pt_55,
                    )}
                >
                    <Grid item style={{ paddingLeft: '0', width: isMobile && !beta ? '100%' : 'auto' }}>
                        <Typography
                            className={clsx(styles.display_price, {
                                [styles.display_price_mv2]: isMobileV2,
                            })}
                        >
                            <SVGPrice displayPriceFormated={displayPriceFormated} width="10" height="11" fillPath="#292929" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice > displayPrice && <TrendingDownIcon className={styles.down} />}
                        {errorCode === ERROR_CODE_CART.CHANGED_PRICE && currentPrice < displayPrice && <TrendingUpIcon className={styles.up} />}
                    </Grid>
                </Grid>
                {isDeal && deal && (
                    <Grid item style={{ width: isMobile && !beta ? 'auto' : 'auto', paddingTop: isMobile ? 4 : 0 }}>
                        <Typography
                            className={clsx(styles.sale_price, styles.strikethrough, styles.flex_align_center, {
                                [styles.sale_price_mv2]: isMobileV2,
                            })}
                        >
                            <SVGPrice displayPriceFormated={salePriceFormated} width="8" height="9.5" fillPath="#797979" line />
                        </Typography>
                    </Grid>
                )}
            </div>
        );
    }

// display price after discount
// TODO: getCurrentPrice - total/quantity
const ProductPriceDecrease = ({ productPrice = 0, quantity = 0, totalDiscountPerItem = 0, displayPrice = 0, isDeal = false, styles, iconPriceFluctuation }) => {
    const priceDecrease = productPrice - Math.floor(totalDiscountPerItem / quantity);

    // product apply voucher but not a deal/flash sale/campaign
    if (!isDeal) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Box display="flex" gridGap={4}>
                    <div className={styles.fluctuation_icon_wrapper} style={{ maxHeight: 18, minWidth: 18 }}>
                        {iconPriceFluctuation}
                    </div>
                    <SVGPrice displayPriceFormated={displayPrice} width="10" height="11" fillPath="#797979" size="sm" line className={styles.voucherApplyTextAlign} />
                </Box>
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <SVGPrice displayPriceFormated={formatCurrency(priceDecrease < -1 ? 0 : priceDecrease)} fillPath="#000" notAutoScale className={styles.voucherApplyTextAlign} />
                    <span style={{ color: '#000', fontFamily: 'ggsm', fontSize: 12, height: '25px', padding: '0 4px 0 0' }}>Giá đã giảm</span>
                </div>
            </div>
        )
    }

    return (
        <div>
            {!isDeal ? <SVGPrice displayPriceFormated={displayPrice} width="10" height="11" fillPath="#797979" size="sm" line className={styles.voucherApplyTextAlign} /> : null}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <SVGPrice displayPriceFormated={formatCurrency(priceDecrease < -1 ? 0 : priceDecrease)} fillPath="#000" notAutoScale className={styles.voucherApplyTextAlign} />
                <span style={{ color: '#000', fontFamily: 'ggsm', fontSize: 12, textAlign: 'right', width: '100%', padding: '0 4px 0 0' }}>Giá đã giảm</span>
            </div>
        </div>
    );
};
export const MemoGetPriceCardInput = memo(GetPriceCardInput);
export const MemoGetPriceCardBuy = memo(GetPriceCardBuy);
